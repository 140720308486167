import React from 'react'
import PageModel from '../models/PageModel'
import './CompatibleTest.css'

export default function CompatibleTest() {
  return (
    <div>
            <PageModel></PageModel>
Compatible
        </div>
  )
}
