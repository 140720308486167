import React from 'react'
import PageModel from '../models/PageModel'

export default function Blog() {
  return (
    <div>
          <PageModel></PageModel>
        Blog</div>
  )
}
